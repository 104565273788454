import { DescriptionDialogComponent } from './../../../../shared/components/dialogs/description-dialog/description-dialog.component';
import { Component, Input, OnInit, ChangeDetectorRef, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AsignauraByPeriodos, IGradeSubject } from 'src/app/shared/interfaces/IAsignatura';
// import { IGradeSubject } from 'src/app/shared/interfaces/ICarrera';
import { DataService } from 'src/app/shared/services/data.service';
import { MatriculacionesService } from 'src/app/shared/services/matriculaciones.service';

@Component({
  selector: 'app-tabs-carreras',
  templateUrl: './tabs-carreras.component.html',
  styleUrls: ['./tabs-carreras.component.scss'],
})
export class TabsCarrerasComponent implements OnInit, OnChanges {
  @Input() filteredCarreras;
  @Input() gradeSubjects: IGradeSubject;
  @Input() periodos;
  @Input() removeMateria;
  @Input() masterySelected;
  @Input() thesisSelected;
  @Input() addMateria;
  @Input() selectMaterias;
  @Input() getUser;
  @Input() pensumElectiveIds;
  @Input() allEspecialidades;
  @Input() getEspecialidadById;
  @Input() especialidadAlumnoCarrera;
  @Input() modalidadSelected;
  activeTabIndex: number = 0;
  gradeSubjectsArray = [];
  asignaturasByPeriodos =  [];
  asignaturasPorPeriodo: any[] = [];

  disabled = true;

  
  public especialidadSelected = null;

  public searchValue = '';

  constructor(
    private service: DataService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private cd : ChangeDetectorRef,
    private matricService: MatriculacionesService
  ) {}

  ngOnInit(): void {
    this.openDescription = this.openDescription.bind(this);
    this.asignaturasPorPeriodo = this.makeAsignaturaByPeriodo(this.filteredCarreras);
    // this.gradeSubjectsArray = Object.values(this.gradeSubjects);
    console.log('GRADE SUBJECTS => ', this.gradeSubjects)
    this.gradeSubjects.mastery_pensums.forEach((mastery) => {
      return mastery.isSelected = false;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filteredCarreras && changes.filteredCarreras.currentValue) {
      this.handleFilteredCarrerasData(changes.filteredCarreras.currentValue);
    }
  }

  handleFilteredCarrerasData(data: any[]): void {
    this.asignaturasPorPeriodo = this.makeAsignaturaByPeriodo(data);
  }

  makeAsignaturaByPeriodo(data: any[]): any[] {
    const carrerasConAsignaturasPorPeriodo = data.map((carrera) => {
      const asignaturasPorPeriodo = {};
      carrera.asignaturas.forEach((asignatura) => {
        if (!asignaturasPorPeriodo[asignatura.periodo_id]) {
          asignaturasPorPeriodo[asignatura.periodo_id] = [];
        }
        asignaturasPorPeriodo[asignatura.periodo_id].push(asignatura);
      });

      carrera.asignaturasPorPeriodo = Object.entries(asignaturasPorPeriodo).map(([periodo_id, asignaturas]) => {
        const periodo = this.periodos.find((p) => p.id === Number(periodo_id));
        return {
          id: Number(periodo_id),
          name: periodo.nombre,
          asignaturasPorPeriodo: asignaturas,
        };
      });

      return carrera;
    });
    return carrerasConAsignaturasPorPeriodo;
  }

  openDescription(asignatura, carreraId, pensumElectiveId): void {
    const dialogRef = this.dialog.open(DescriptionDialogComponent, {
      width: '450px',
      maxWidth: '90vw',
      data: {
        title: 'Tutorial automatriculación',
        asignatura,
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addMateria(
          result,
          carreraId,
          pensumElectiveId,
        );
      }
    });
  }

  setSelectedEspecialidad(especialidad_id) {
    this.especialidadSelected = especialidad_id;
  }

  resetSelectedEspecialidad() {
    this.especialidadSelected = null;
  }

  checkEspecialidadSelection(asignaturas): boolean {
    return asignaturas.some(
      (asignatura) =>
        asignatura.tipo === 'elective' && asignatura.elegir.length > 0
    );
  }

  existenElectivasPorEspecialidad(
    asignaturas,
    especialidad_id,
    especialidad
  ): boolean {
    return especialidad === 'CSE' || especialidad === 'NSE'
      ? true
      : asignaturas.some(
          (asignatura) => asignatura.especialidad_id === especialidad_id
        );
  }

  search(evt) {
    evt.preventDefault();
  }

  cleanSearch(aver=false): void {
    this.searchValue = '';
    if(aver){
      console.log('a')
    }
  }
}
