
export interface Asignatura {
    subject_id: number;
    pensum_id: number;
    nombre: string;
    inscriptos: number;
    isSecond?: boolean;
    maxima: number;
    modelo: string;
    horario: string;
    descripcion?: string;
    horarios?: string[];
    estado_asignatura: string;
    estado_alumno: string;
    isSelected?: boolean;
    elegir?: Asignatura[];
    secuencia?: number;
    tipo?: string;
    periodo?: string;
    periodo_id?: number;
    tipo_credito_id?: number;
    especialidad: any;
    especialidad_id: any;
}

export interface AsignauraByPeriodos{
    id: number;
    name: string;
    asignaturas: Asignatura[];
}

export interface MasteryPensum {
    passed: boolean;
    pensum_elective_id: number;
    pensum_id: number;
    nombre: string;
    periodo: string;
    maxuma: number;
    periodo_id: number;
    isSelected?: boolean;
    subject_id: number;
    tipo_credito_id: number;
  }
 export interface ThesisSubject {
    pensum_elective_id: number;
    nombre: string;
    isSelected: boolean;
    periodo: string;
    maxuma: number;
    pensum_id: number | boolean;
    periodo_id: number;
    subject_id: number | boolean;
    tipo_credito_id: number | boolean;
  }
  export interface IGradeSubject {
    mastery_pensums: MasteryPensum[];
    thesis_subject: ThesisSubject;
}
