import { TourService } from 'ngx-ui-tour-md-menu';
import { TransferDialogComponent } from './../transfer-dialog/transfer-dialog.component';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CustomStepperComponent } from 'src/app/shared/components/display/custom-stepper/custom-stepper.component';
import { CartProduct } from 'src/app/shared/interfaces/ICart';
import { Product, PostProduct } from 'src/app/shared/interfaces/IProduct'; 
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentLinkDialogComponent } from '../../components/payment-link-dialog/payment-link-dialog.component';
import moment from 'moment';
import { dataUri } from '@rxweb/reactive-form-validators';
import { cartTour } from 'src/app/shared/data/tours';
import { cortePresencial2023 } from 'src/app/shared/data/rematricula';

export interface DialogData {
  title: string;
  limit: number;
  opportunity: Oportunidad;
  products: Product[];
  idOportunidad: number;
  cart: CartProduct[];
  cantDaysDiffer: number;
  limitCuotas: number;
  fechaInicio: any;
  minAsigments: number;
  ifharu: boolean;
  careerSelected: any;
  user: any;
  modalidad: string;
  fixedCosts: any[];
  dont_pay_fixed_cost: boolean;
  educatId: string;
  becas: any[];
}
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { AlertDialogComponent } from 'src/app/shared/components/alerts/alert-dialog/alert-dialog.component';
import { FinancialCreditsService } from 'src/app/shared/services/financial-credits.service';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Oportunidad } from 'src/app/shared/interfaces/IEstadoFinanciero';
import { Router } from '@angular/router';
import { FileViewerDialogComponent } from 'src/app/shared/components/dialogs/file-viewer-dialog/file-viewer-dialog.component';
@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CarritoComponent implements OnInit, AfterViewInit{
  /* CARRITO */
  public products: Product[] = this.data.products;
  public moment = moment;
  public cart: CartProduct[] = [];
  public units: number = 0;
  normalQuantityUnits = 0;
  public subTotalAsignaturas: number = 0;
  termCheck: boolean = false;
  public subTotalNormal: number = 0;
  public subTotalGrad: number = 0;
  public totalCompra: number = 0;
  public totalCompraWithCartInfo: any;
  public finalDiscounts: any[] = [];

  public cartLimit: number = 7;

  public regularOpp = true;
  public gradOpp = true;
  /* FORMA PAGO */
  public formaPago = this.data.ifharu ? 'transferencia/cheque' : 'online';
  public cantCuotas = 1;
  public limitCuotas =
    this.data.limitCuotas != null && this.data.limitCuotas > 0
      ? this.data.limitCuotas
      : 1;
  public nroComprobante = '';
  /* DIFERIMIENTO PAGO */
  public cantDaysDiffer =
    this.data.cantDaysDiffer !== undefined ? this.data.cantDaysDiffer : 7;
  public minDate;
  public maxDate;
  public dateControl;
  isSpecMode = false;
  public differPayControl;
  public currentDate = new Date();
  /* CONDITIONAL RENDER */
  public sending = false;
  /* DATOS BANCARIOS */
  public bankData = {
    online: {
      banco: 'Banco Benistmo',
      cuenta: 'Cuenta Corriente Nº 0114535215',
      nombre: 'Universite de Management de Suisse',
    },
    ifharu: {
      banco: 'Banco Benistmo',
      cuenta: 'Cuenta Corriente Nº 101087584',
      nombre: 'Alta Dirección S.A.',
    },
    presencial: {
      banco: 'Bac International Bank',
      cuenta: 'Cuenta Corriente Nº 104174321',
      nombre: 'Alta Dirección S.A.',
    },
  };
  constructor(
    public dialogRef: MatDialogRef<CarritoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private alertService: AlertService,
    private _snackBar: MatSnackBar,
    private financialCreditsService: FinancialCreditsService,
    private tourService3: TourService,
    private specViewService: SpecViewService 
  ) {}
  @ViewChild('stepper') stepper: CustomStepperComponent;

  ngOnInit(): void {
    console.log('EDUCAT ID', this.data.educatId);
    console.log('ID OPORTUNIDAD', this.data.idOportunidad);
    /* BINDING */
    this.specViewService.currentSharedSpecMode.subscribe(data => this.isSpecMode = data.isSpecMode)
    this.addProduct = this.addProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.isInCart = this.isInCart.bind(this);
    this.isInCartLimit = this.isInCartLimit.bind(this);
    this.isGradOption = this.isGradOption.bind(this);
    /* DATE */
    this.minDate = moment().add(1, 'day').toDate();
    this.dateControl = new UntypedFormControl(this.minDate, [
      Validators.required,
    ]);
    this.differPayControl = new UntypedFormControl(false);
    /* SET LIMITES COSTOS */
    if (this.data.cart.length > 0) {
      this.cart = this.data.cart.filter((item) => item.subtotal > 0);
      this.cart.forEach((item) => {
        this.subTotalAsignaturas += item.subtotal;

        if (item.id === 1 || item.id === 7)
          this.subTotalNormal += item.subtotal;

        if (item.id === 4 || item.id === 2 || item.id === 3)
          this.subTotalGrad += item.subtotal;
        
        this.totalCompra = this.totalCompra + item.subtotal;
        this.units = this.units + item.quantity;
        this.normalQuantityUnits = item.id != 19 && item.id !== 7 ? this.normalQuantityUnits + item.quantity : this.normalQuantityUnits;
      });
    }
    if (
      this.data.modalidad === 'presencial' &&
      !this.data.dont_pay_fixed_cost
    ) {
      this.totalCompra =
        this.totalCompra +
        this.getFixedCostByID(8) +
        this.getFixedCostByID(14);
      this.data.fixedCosts.forEach((item) => {
        // this.totalCompra += item.monto;
        item.tipo_credito_id === 14 &&
          cortePresencial2023.includes(this.data.educatId)
            ? 410
            : item.monto;
      });
    }
    if (this.data.products.length > 0) {
      this.initTour();
    }
    if(this.data.products.find(product => product.price <= 0)){
      console.error('PRODUCTO SIN PRECIO')
    }
    if(this.data.products.find(product => product.show === false)){
      console.error('PRODUCTO NO HABILITADO')
    }
  }
  ngAfterViewInit(): void {
    // Establece maxDate después de que el diálogo se haya abierto
    this.dialogRef.afterOpened().subscribe(() => {
      this.maxDate = moment(this.data.fechaInicio)
        .add(this.data.cantDaysDiffer, 'day')
        .toDate();
    });
  }
  /* VALIDATE CART */
  public checkCartType() {
    if (
      this.cart.some(
        (item) =>
          item.id === 2 ||
          item.id === 3 ||
          item.id === 4 ||
          item.id === 5 ||
          item.id === 6
      )
    ) {
      this.regularOpp = false;
      this.gradOpp = true;
    } else if (this.cart.some((item) => item.id === 1 || item.id === 7)) {
      this.regularOpp = true;
      this.gradOpp = false;
    } else if (this.cart.length === 0) {
      this.regularOpp = true;
      this.gradOpp = true;
    }
  }

  showTerms(){
    this.dialog.open(FileViewerDialogComponent, {
      width: '80vw',
      data: {
        title: 'Términos y condiciones',
        url: '',
      },
      panelClass: 'generic-alert',
    })
  }

  termsChange(event){
    this.termCheck = event.checked;
    console.log('TERMS', this.termCheck)
    console.log('EVENT', event)
  }

  /* ACCIONES CARRITO */
  public addProduct(product: Product, quantity: number): void {
    console.log(this.units)
    if (this.units + quantity <= this.cartLimit) {
      if (!this.isInCart(product)) {
        this.cart.push({
          id: product.id,
          type: product.type,
          tipo_certificado: product.tipo_certificado,
          name: product.name,
          price: product.price,
          quantity: quantity,
          limit: product.limit,
          subtotal: product.price * quantity,
        });
        
        if (product.id === 1 || product.id === 15) {
          this.subTotalNormal = this.subTotalNormal + product.price * quantity;
        }

        if (product.id === 4 || 
          product.id === 2 || 
          product.id === 3 || 
          product.id === 5 || 
          product.id === 16 || 
          product.id === 17 || 
          product.id === 21 || 
          product.id === 18 ) 
          {
            this.subTotalGrad = this.subTotalGrad + product.price * quantity;
          }
        console.log('product', product)
        this.subTotalAsignaturas =
          this.subTotalAsignaturas + product.price * quantity;
        this.totalCompra = this.totalCompra + product.price * quantity;
        this.units = this.units + quantity;
        this.normalQuantityUnits = product.id != 19 && product.id != 7 ? this.normalQuantityUnits + quantity : this.normalQuantityUnits;
      } else if (this.isInCart(product) && product.type === 'opción de grado') {
        this.openSnackBar(
          '¡Ya seleccionaste una opción de grado!',
          'OK',
          'error'
        );
        return;
      } else {
        this.cart = this.cart.map((item) => {
          if (item.id === product.id) {
            if (item.quantity === product.limit) {
              return item;
            } else {
              item.quantity += quantity;
              item.subtotal += product.price * quantity;
              console.log('product', product)
              if (product.id === 1 || product.id === 7 || product.id === 15 || product.id === 19) {
                this.subTotalNormal =
                  this.subTotalNormal + product.price * quantity;
              }

              if (product.id === 4 || product.id === 2 || product.id === 3 || product.id === 18 || product.id === 16 || product.id === 17) {
                this.subTotalGrad =
                  this.subTotalGrad + product.price * quantity;
              }
              this.subTotalAsignaturas =
                this.subTotalAsignaturas + product.price * quantity;
              this.totalCompra = this.totalCompra + product.price * quantity;
              this.units = this.units + quantity;
              this.normalQuantityUnits = product.id != 19 && product.id != 7 ? this.normalQuantityUnits + quantity : this.normalQuantityUnits;
            }
          }
          return item;
        });
      }
      this.checkCartType();
      this.openSnackBar('¡Producto añadido con éxito!', 'OK', 'success');
    } else {
      this.openSnackBar('¡Tu carrito está lleno!', 'OK', 'error');
    }
  }
  public updateProduct(product: CartProduct, quantity: number): void {
    this.cart = this.cart.map((item) => {
      if (
        item.id === product.id &&
        this.units - item.quantity + quantity <= this.cartLimit
      ) {
        if (product.id === 1 || product.id === 15) {
          console.log('UPDATE PRODUCT', product)
          this.subTotalNormal =
            this.subTotalNormal - item.subtotal + product.price * quantity;
        }

        if (product.id === 4 || product.id === 2 || product.id === 3) {
          this.subTotalGrad =
            this.subTotalGrad - item.subtotal + product.price * quantity;
        }

        this.subTotalAsignaturas =
          this.subTotalAsignaturas - item.subtotal + product.price * quantity;
        this.totalCompra =
          this.totalCompra - item.subtotal + product.price * quantity;
        this.units = this.units - item.quantity + quantity;
        this.normalQuantityUnits = item.id != 19 && item.id != 7 ? this.normalQuantityUnits - item.quantity + quantity : this.normalQuantityUnits;
        item.quantity = quantity;
        item.subtotal = product.price * quantity;
      }
      console.log('UPDATE PRODUCT', product)
      return item;
    });
  }
  public removeProduct(product: CartProduct): void {
    console.log('REMOVE PRODUCT', product)
    this.cart = this.cart.filter((item) => item.id !== product.id);
    if (product.id === 1 || product.id === 15) {
      this.subTotalNormal -= product.subtotal;
    }

    if (product.id === 4 || product.id === 2 || product.id === 3) {
      this.subTotalGrad -= product.subtotal;
    }
    
    this.subTotalAsignaturas = this.subTotalAsignaturas - product.subtotal;
    this.totalCompra = this.totalCompra - product.subtotal;
    this.units = this.units - product.quantity;
    this.normalQuantityUnits = product.id != 19 && product.id != 7 ? this.normalQuantityUnits - product.quantity : this.normalQuantityUnits;
    this.checkCartType();
    this.getContadoDiscount();
  }
  public isInCart(product: Product): boolean {
    return this.cart.find((item) => item.id === product.id) !== undefined;
  }
  public isGradOption(product: Product): boolean {
    return this.cart.some(
      (item) =>
        (product.id === 4 || product.id === 5 || product.id === 6) &&
        (item.id === 4 || item.id === 5 || item.id === 6)
    );
  }

  public disabledProduct(product: Product){
    if(this.cart.length > 0 && this.isInCart(product)){
      return true
    }
    return false
  }

  public isInCartLimit(product: Product, quantity: number): boolean {
    const item = this.cart.find((item) => item.id === product.id);
    return (
      item !== undefined &&
      (item.quantity === product.limit ||
        item.quantity + quantity > product.limit)
    );
  }
  public clearCart(): void {
    this.cart = [];
    this.subTotalAsignaturas = 0;
    this.subTotalGrad = 0;
    this.subTotalNormal = 0;
    this.units = 0;
    this.normalQuantityUnits = 0;
    this.regularOpp = true;
    this.gradOpp = true;
    if (this.data.modalidad === 'presencial') {
      this.totalCompra =
        this.data.user.costo_matricula + this.data.user.costo_seguro;
    } else {
      this.totalCompra = 0;
    }
  }
  /* CUOTAS */
  removeOneCuota(): void {
    if (this.cantCuotas > 1) {
      this.cantCuotas--;
    }
  }
  addOneCuota(): void {
    if (this.cantCuotas < this.limitCuotas) {
      this.cantCuotas++;
    }
  }
  /* FORMATO */
  formatProducts(): PostProduct[] {
    const aux: PostProduct[] = [];
    this.cart.forEach((prod) => {
      aux.push({
        creditTypeId: prod.id,
        cantidad: prod.quantity,
        monto:
          prod.price,
      });
    });
    if (
      this.data.modalidad === 'presencial' &&
      !this.data.dont_pay_fixed_cost
    ) {
      this.data.fixedCosts.forEach((item) => {
        aux.push({
          creditTypeId: item.tipo_credito_id,
          cantidad: 1,
          // monto: item.monto,
          monto:  
            item.tipo_credito_id === 14 &&
            cortePresencial2023.includes(this.data.educatId)
              ? 410
              : this.getFixedCostByID(item.tipo_credito_id),
        });
      });
    }
    return aux;
  }
  /* BECAS */

  getActiveBecas() {}

  getBecas() {
    return this.data.becas.map((item) => {
      return {
        name: item.nombre_beca,
        discount: item.descuento_real,
        price:
          item.aplica_pasantias_grado && this.subTotalGrad > 0
            ? (this.subTotalGrad * item.descuento_real) / 100
            : (this.subTotalNormal * item.descuento_real) / 100,
      };
    });
  }
  /* TOUR */
  initTour() {
    this.tourService3.initialize(cartTour);
  }
  startTour() {
    if (this.stepper.selectedIndex === 0) {
      this.tourService3.start();
    } else {
      this.tourService3.startAt(4);
    }
  }
  /* PAGO */
  getDiscount(): number {
    return this.getTotalDiscountOfNormal() * 0.05;
  }
  completePurchase(){
    if(!this.isSpecMode){
      if(this.data.modalidad != 'online' && this.data.modalidad != 'presencial'){
        this.alertService.openAlert('Ups', 'Algo salio mal, modalidad: ' + this.data.modalidad, 'Ok')
      }else{
        this.setFinalDiscounts();
        const oportunidad = {
          productos: this.formatProducts(),
          cuotas: this.cantCuotas,
          monto:
            !this.data.ifharu && this.normalQuantityUnits >= 4 && this.cantCuotas === 1
              ? this.getTotalDiscount() / this.cantCuotas
              : this.totalCompra / this.cantCuotas,
          discounts: this.finalDiscounts,
          numero_pago: this.formaPago != 'online' ? this.nroComprobante : '',
          forma_pago: this.formaPago,
          dia_pago_diferido:
            this.differPayControl.value && this.dateControl.value != null
              ? new Date(this.dateControl.value).getDate()
              : 0,
          diferir_pago_a_mes_proximo: this.differPayControl.value,
          programa_academico:
            this.data.modalidad === 'online'
              ? 'rematriculaOnline'
              : 'rematriculaPresencial',
        };
        if (this.formaPago === 'online') {
          document.getElementById('btn-confirm-link').innerHTML = 'Cargando...';
        } else {
          document.getElementById('btn-confirm-transfer').innerHTML = 'Cargando...';
        }
        if (this.data.idOportunidad !== null && !this.sending) {
          this.updateOportunidad(oportunidad);
        } else if (!this.sending) {
          this.postCredits(oportunidad);
          console.log('Oportunidad', oportunidad);
        }
      }
    }else{
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '400px',
      data: {
        title: 'No puedes realizar cambios',
        description: 'Recuerda que únicamente puedes ver la plataforma, sin la posibilidad de hacer modificaciones.',
        action: 'Entendido',
        img: '../../../../../assets/images/content/stop.png' 
      },
      panelClass: 'plain-alert',
    });
    }
  }
  getPriceDiscount(price): number {
    return price * 0.05;
  }
  getTotalDiscount(): number {
    let auxTotal = this.totalCompra;
    // console.log('TOTAL COMPRA => ', this.totalCompra)
    this.data.becas.forEach((beca) => {
      if (
        (beca.aplica_pasantias_grado && this.subTotalGrad > 0) ||
        this.subTotalNormal > 0
      ) {
        // console.log('SUB TOTAL COMPRA => ', this.subTotalNormal)
        let discount = (this.subTotalNormal * beca.descuento_real) / 100;
        auxTotal = auxTotal - discount;
      }
    });

    auxTotal = Number(auxTotal.toFixed(2));

    return auxTotal;
  }
  getTotalDiscountOfNormal(): number {
    let auxTotal = this.subTotalNormal;
    this.data.becas.forEach((beca) => {
      if (
        (beca.aplica_pasantias_grado && this.subTotalGrad > 0) ||
        this.subTotalNormal > 0
      ) {
        let discount = (this.subTotalNormal * beca.descuento_real) / 100;
        auxTotal = auxTotal - discount;
      }
    });

    auxTotal = Number(auxTotal.toFixed(2));
    return auxTotal;
  }

  getPercentageDiscount(item): number {
   return ((this.subTotalNormal * item.descuento_real) / 100)
  }

  getContadoDiscount(): number {
    if (!this.data.ifharu && this.normalQuantityUnits >= 4 && this.cantCuotas === 1) {
      let auxTotal = this.getTotalDiscount() - this.getDiscount();
      auxTotal = Number(auxTotal.toFixed(2));
      return auxTotal;
    } else {
      return this.getTotalDiscount();
    }
  }

  setFinalDiscounts() {
    if (!this.data.ifharu && this.normalQuantityUnits >= 4 && this.cantCuotas === 1) {
      this.finalDiscounts.push({discountName: 'Pago contado', discountPercentage: 5});
    }
    this.data.becas.forEach((beca) => {
      console.log('Beca', beca )
      if (
        (beca.aplica_pasantias_grado && this.subTotalGrad > 0) ||
        this.subTotalNormal > 0
      ) {
        this.finalDiscounts.push({discountName: beca.nombre_beca, discountPercentage: beca.descuento_real});
      }
    });
  }
  getFixedCostByID(id): number {
    const costo_seguro = this.data.user.carreras[0].costo_seguro;
    const costo_gastos_adm = this.data.user.carreras[0].costo_gastos_adm;
    if(id === 14 && cortePresencial2023.includes(this.data.educatId)){
      return 410;
    }
    switch (id) {
      case 8:
        return costo_seguro;
      case 14:
        return costo_gastos_adm;
    }
    // return id === 14 && cortePresencial2023.includes(this.data.educatId)
    //   ? 410
    //   : this.data.fixedCosts.find((item) => item.tipo_credito_id === id).monto;
      // return this.data.fixedCosts.find((item) => item.tipo_credito_id === id).monto;
  }

  getBankData() {
    return this.data.ifharu
      ? this.bankData.ifharu
      : this.data.modalidad === 'presencial'
      ? this.bankData.presencial
      : this.bankData.online;
  }
  finishPurchase(): void {
    console.log('CAREER SELECTED => ', this.data.careerSelected)
    if(this.data.modalidad != 'online' && this.data.modalidad != 'presencial'){
      this.alertService.openAlert('Ups', 'Algo salio mal, modalidad: ' + this.data.modalidad, 'Ok')
    }else{
      if(!this.isSpecMode){
        this.setFinalDiscounts();
        const oportunidad = {
          productos: this.formatProducts(),
          cuotas: this.cantCuotas,
          career_name: `${this.data.careerSelected.carrera} | ${this.data.careerSelected.resolucion}`,
          monto: this.getContadoDiscount() < 0 ? 0 : this.getContadoDiscount(),
          discounts: this.finalDiscounts,
          numero_pago: this.formaPago != 'online' ? this.nroComprobante : '',
          forma_pago: this.formaPago,
          fecha_pago_diferido:
            this.differPayControl.value && this.dateControl.value != null
              ? this.dateControl.value
              : new Date(),
          diferir_pago_a_mes_proximo: this.differPayControl.value,
          programa_academico:
            this.data.modalidad === 'online'
              ? 'rematriculaOnline'
              : 'rematriculaPresencial',
        };
    
        if (this.formaPago === 'online') {
          document.getElementById('btn-confirm-link').innerHTML = 'Cargando...';
        } else {
          document.getElementById('btn-confirm-transfer').innerHTML = 'Cargando...';
        }
        
        console.log('Oportunidad', oportunidad);
        
        if (this.data.idOportunidad !== null && !this.sending) {
          this.updateOportunidad(oportunidad);
        } else if (!this.sending) {
          this.postCredits(oportunidad);
        }
        console.log('Oportunidad', oportunidad);
      }else{
        this.setFinalDiscounts();
        const oportunidad = {
          productos: this.formatProducts(),
          cuotas: this.cantCuotas,
          career_name: `${this.data.careerSelected.carrera} | ${this.data.careerSelected.resolucion}`,
          monto: this.getContadoDiscount() < 0 ? 0 : this.getContadoDiscount(),
          discounts: this.finalDiscounts,
          numero_pago: this.formaPago != 'online' ? this.nroComprobante : '',
          forma_pago: this.formaPago,
          fecha_pago_diferido:
            this.differPayControl.value && this.dateControl.value != null
              ? this.dateControl.value
              : new Date(),
          diferir_pago_a_mes_proximo: this.differPayControl.value,
          programa_academico:
            this.data.modalidad === 'online'
              ? 'rematriculaOnline'
              : 'rematriculaPresencial',
        };
        console.log('OPORTUNIDAD => ', oportunidad)
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '400px',
          data: {
            title: 'No puedes realizar cambios',
            description: 'Recuerda que únicamente puedes ver la plataforma, sin la posibilidad de hacer modificaciones.',
            action: 'Entendido',
            img: '../../../../../assets/images/content/stop.png' 
          },
          panelClass: 'plain-alert',
        });
      }
    }
  }
  postCredits(oportunidad): void {
    this.sending = true;
    console.log('ready')
    this.financialCreditsService.postCredits(oportunidad).subscribe({
      next: (res) => {
        if (this.formaPago === 'online') {
          window.open(res.link, '80', '80');
          this.openCreateLink(res.link, res.numero_pago);
        }
        this.resetButtons();
        this.sending = false;
        this.dialogRef.close({ formaPago: this.formaPago });
      },
      error: (err) => {
        this.resetButtons();
        this.alertService.openAlert(
          'Algo salió mal',
          'No pudimos procesar tu compra. Por favor, intenta de nuevo más tarde',
          'Ok'
        );
        this.sending = false;
      },
    });
  }
  updateOportunidad(oportunidad): void {
    this.sending = true;
    this.financialCreditsService
      .updateOportunidad(this.data.idOportunidad, oportunidad)
      .subscribe({
        next: (res) => {
          if (this.formaPago === 'online') {
            console.log('El link de pago es:', res.link);
            window.open(res.link, '80', '80');
            this.openCreateLink(res.link, res.numero_oportunidad);
          } /* else {
            this.openTransferInfo(124578, oportunidad);
          } */
          this.resetButtons();
          this.sending = false;
          this.dialogRef.close({ formaPago: this.formaPago });
        },
        error: (err) => {
          this.alertService.openAlert(
            'Algo salió mal',
            'No pudimos procesar tu compra. Por favor, intenta de nuevo más tarde',
            'Ok'
          );
          this.resetButtons();
          this.sending = false;
        },
      });
  }
  openCreateLink(link: string, nroPago: number): void {
    const dialogRef2 = this.dialog.open(PaymentLinkDialogComponent, {
      width: '400px',
      data: {
        link,
        nro_pago: nroPago,
      },
      panelClass: 'generic-alert',
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((cart) => {
      window.location.reload();
    });
  }
  openTransferInfo(nroPago: number, oportunidad): void {
    const dialogRef2 = this.dialog.open(TransferDialogComponent, {
      width: '80vw',
      data: {
        nro_pago: nroPago,
        user: this.data.user,
        ifharu: this.data.ifharu,
        total: this.subTotalAsignaturas,
        oportunidad: oportunidad,
      },
      panelClass: 'generic-alert',
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((cart) => {
      window.location.reload();
    });
  }
  resetButtons(): void {
    if (this.formaPago === 'online') {
      document.getElementById('btn-confirm-link').innerHTML = 'Generar link';
    } else {
      document.getElementById('btn-confirm-transfer').innerHTML =
        'Finalizar compra';
    }
  }
  /* DIALOG ACTIONS */
  onClose(): void {
    this.dialogRef.close();
  }
  sendEmail() {
    const mail1 = 'cobros@adenuniversity.edu.pa';
    const mail2 = 'gestorrecaudo@adenuniversity.edu.pa';
    window.open(
      `mailto:${mail1}?subject=Comprobante+de+pago+%7C+${this.data.user.nombre}+%7C+ID+Estudiante%3A+${this.data.user.legajo}&cc=${mail2}&body=Completa los siguientes datos antes de enviar:%0A%0ANOMBRE COMPLETO: ${this.data.user.nombre}%0AID DE ESTUDIANTE: ${this.data.user.legajo}%0AMONTO TRANSFERIDO:%0AFECHA DE TRANSFERENCIA:%0ANÚMERO DE OPERACIÓN:
      %0A%0A¡Recurda adjuntar el comprobante como imagen o PDF!`,
      '_blank'
    );
  }
  scrollToDate(): void {
    document.getElementById('differPay').scrollIntoView(true);
  }
  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: [`${type}-snackbar`],
    });
  }
  showAlertMoneda(): void {
    console.log(this.data.minAsigments);
    this.alertService.openAviso(
      '¡Atención!',
      `<p>Si realizas tus pagos en moneda diferente a Dólar, debes seleccionar la opción de <span class='chip'>Transferencia / ACH</span>  y contactarte con tu Academic Advisor</p>`
    );
  }
}