import { Asignatura, IGradeSubject } from "./IAsignatura";

export interface CarreraPrincipal {
  carrera: string;
  carrera_estudiante: number;
  costo_asignatura_full: number;
  costo_pract_social?: number;
  especialidad: any;
  especialidad_id: any;
  no_limite_minimo: boolean;
  habilitada?: boolean;
  estado_bloqueo: string;
  estado_carrera: EstadoCarrera;
  id: number;
  modalidad?: string;
  nombre?: string;
  planes: PlanEstudio[];
  resolucion: string;
  aprobacion?: string;
}

export interface IDirectorio {
  mostrar_datos_contacto: boolean;
  mostrar_datos_personales: boolean;
  usar_directorio: boolean;
}

  export interface EstadoCarrera {
    aprobadas: number;
    conv: number;
    cursando: number;
    desaprobradas: number;
    pendientes: number;
    preconv: number;
    progreso: string;
    total: number;
  }

  export interface NotasCarreras{
    career_id: string;
    career_lines: [];
    career_name: string;
    career_status: EstadoCarrera;
  }

  export interface PlanEstudio {
    asignatura?: string;
    asinatura?: string;
    codigo: string;
    credito: string;
    periodo: string;
    Prerequisito: string;
  }
  export interface CareerCourse {
    code: string;
    id: number;
    name: string;
    resolution?: string;
  }

  export interface CarreraMatricula {
    asignaturas: Asignatura[];
    carrera: CarreraSimple;
    asignaturas_grado: IGradeSubject;
    limite_ol?: number;
    limite_pr?: number;
    limite_ol_dual?: number;
    limite_pr_dual?: number;
  }
  
  export interface CarreraPeriodos {
    carrera: CarreraSimple;
    periodos: Asignatura[];
  }

  
  export interface CarreraSimple {
    career_id: number;
    nombre: string;
    habilitado: boolean;
    resolucion: string;
    modalidad: string;
    especialidad: any;
    especialidad_id: any;
    student_career_id?: number;
    graduando?: boolean;
}

export interface Periodo {
  periodo: string;
  periodo_id: number;
}
  
  
  