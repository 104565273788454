import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ItemPecuniarioCardComponent } from './pages/derechos-pecuniarios/components/item-pecuniario-card/item-pecuniario-card.component';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContestLayoutComponent } from './shared/layouts/contest-layout.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { AcademicAdvisorComponent } from './pages/academic-advisor/academic-advisor.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { NotasComponent } from './pages/notas/notas.component';
import { PlanesEstudioComponent } from './pages/planes-estudio/planes-estudio.component';
import { TramitesComponent } from './pages/tramites/tramites.component';
import { CambioCarreraComponent } from './pages/tramites/components/cambio-carrera/cambio-carrera.component';
import { CartasComponent } from './pages/tramites/components/cartas/cartas.component';
import { CreditosComponent } from './pages/tramites/components/creditos/creditos.component';
import { DobleTitulacionComponent } from './pages/tramites/components/doble-titulacion/doble-titulacion.component';
import { RetiroCarreraComponent } from './pages/tramites/components/retiro-carrera/retiro-carrera.component';
import { CountdownModule } from 'ngx-countdown';
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HomeLayoutComponent } from './shared/layouts/home-layaot.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { CardComponent } from './pages/home/components/card/card.component';
import { CardcanvasComponent } from './pages/home/components/cardcanvas/cardcanvas.component';
import { CardcareerComponent } from './pages/planes-estudio/components/cardcareer/cardcareer.component';
import { CardadvisorComponent } from './pages/academic-advisor/components/cardadvisor/cardadvisor.component';
import { FormComponent } from './pages/perfil/components/form/form.component';
import {
  CardprofileComponent,
  ModalComponent,
} from './pages/perfil/components/cardprofile/cardprofile.component';
import { MatriculacionesComponent } from './pages/matriculaciones/matriculaciones.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Redux
import { StoreModule } from '@ngrx/store';

import { ModalConfirm } from './pages/matriculaciones/components/modal/modal.component';
import { BannerComponent } from './pages/home/components/banner/banner.component';
import { CambioModalidadComponent } from './pages/tramites/components/cambio-modalidad/cambio-modalidad.component';
import { PaymentLinkDialogComponent } from './pages/matriculaciones/components/payment-link-dialog/payment-link-dialog.component';
import { store } from './redux/store/store';
import { CreditosAcademicosComponent } from './pages/notas/components/creditos-academicos/creditos-academicos.component';
import { EventosBannerComponent } from './pages/home/components/eventos-banner/eventos-banner.component';
import { ContractDialogComponent } from './pages/matriculaciones/components/contract-dialog/contract-dialog.component';
import { TransferDialogComponent } from './pages/matriculaciones/components/transfer-dialog/transfer-dialog.component';
import { ConcursoComponent } from './pages/ceaden/aprende-emprende/concurso.component';
import { CronogramaConcursoComponent } from './pages/ceaden/aprende-emprende/cronograma-concurso/cronograma-concurso.component';
import { FormConcursoComponent } from './pages/ceaden/aprende-emprende/form-concurso/form-concurso.component';
import { HeadConcursoComponent } from './pages/ceaden/aprende-emprende/head-concurso/head-concurso.component';
import { SobreConcursoComponent } from './pages/ceaden/aprende-emprende/sobre-concurso/sobre-concurso.component';
import { CardRequisitoComponent } from './pages/ceaden/aprende-emprende/sobre-concurso/card-requisito/card-requisito.component';
import { CardCriterioComponent } from './pages/ceaden/aprende-emprende/sobre-concurso/card-criterio/card-criterio.component';
import { CardMiembroComponent } from './pages/ceaden/aprende-emprende/form-concurso/card-miembro/card-miembro.component';
import { CardTutorComponent } from './pages/ceaden/aprende-emprende/form-concurso/card-tutor/card-tutor.component';
import { PremiosConcursoComponent } from './pages/ceaden/aprende-emprende/premios-concurso/premios-concurso.component';
import { CardCronogramaComponent } from './pages/ceaden/aprende-emprende/cronograma-concurso/card-cronograma/card-cronograma.component';
import { ContactoConcursoComponent } from './pages/ceaden/aprende-emprende/contacto-concurso/contacto-concurso.component';
import { PeticionesComponent } from './pages/peticiones/peticiones.component';
import { NuevoTicketComponent } from './pages/peticiones/components/nuevo-ticket/nuevo-ticket.component';
import { DialogSuccess } from './pages/peticiones/components/nuevo-ticket/nuevo-ticket.component';
import { EstadoTicketComponent } from './pages/peticiones/components/estado-ticket/estado-ticket.component';
import { UploadConcursoComponent } from './pages/ceaden/aprende-emprende/form-concurso/upload-concurso/upload-concurso.component';
import { DndDirective } from './pages/ceaden/aprende-emprende/form-concurso/upload-concurso/dnd.directive';

import { DndInscripcionDirective } from './pages/adenu-internacional/upload-internacional/dnd-inscripcion.directive';
import { UploadInternacionalComponent } from './pages/adenu-internacional/upload-internacional/upload-internacional.component';
import { ContactoInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/contacto-internacional/contacto-internacional.component';
import { CardCronogramaInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/cronograma-internacional/card-cronograma-internacional/card-cronograma-internacional.component';
import { PasosInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/pasos-internacional/pasos-internacional.component';
import { CardPasoInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/sobre-internacional/card-paso-internacional/card-paso-internacional.component';
import { SobreInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/sobre-internacional/sobre-internacional.component';
import { EstadoFinancieroComponent } from './pages/estado-financiero/estado-financiero.component';
import { EditPaymentComponent } from './pages/matriculaciones/components/edit-payment/edit-payment.component';
import { PaymentCardComponent } from './pages/estado-financiero/components/payment-card/payment-card.component';
import { PaymentTableComponent } from './pages/estado-financiero/components/payment-table/payment-table.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { CardEventoComponent } from './pages/eventos/components/card-evento/card-evento.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { GanadoresConcursoComponent } from './pages/ceaden/aprende-emprende/ganadores-concurso/ganadores-concurso.component';
import { PlanTableComponent } from './pages/planes-estudio/components/plan-table/plan-table.component';
import { MiProgresoComponent } from './pages/notas/components/mi-progreso/mi-progreso.component';
import { CardAsignaturaComponent } from './pages/matriculaciones/components/card-asignatura/card-asignatura.component';
import { AlertsMatriculaComponent } from './pages/matriculaciones/components/alerts-matricula/alerts-matricula.component';
import { MovilidadDocentesComponent } from './pages/adenu-internacional/movilidad-docentes/movilidad-docentes.component';
import { FormularioDocentesComponent } from './pages/adenu-internacional/movilidad-docentes/formulario-docentes/formulario-docentes.component';
// eslint-disable-next-line max-len
import { HeadInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/head-internacional/head-internacional.component';
// eslint-disable-next-line max-len
import { FormInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/form-internacional/form-internacional.component';
import { CronogramaInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/cronograma-internacional/cronograma-internacional.component';
import { AdenuInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/adenu-internacional.component';
import { CardRequisitoInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/sobre-internacional/card-requisito-internacional/card-requisito-internacional.component';
import { FichaInscripcionComponent } from './pages/adenu-internacional/ficha-inscripcion/ficha-inscripcion.component';
import {
  ConveniosComponent,
  DetailDialogComponent,
} from './pages/adenu-internacional/movilidad-salientes/convenios/convenios.component';
import { MovilidadEntrantesComponent } from './pages/adenu-internacional/movilidad-entrantes/movilidad-entrantes.component';
import { FormularioEntrantesComponent } from './pages/adenu-internacional/movilidad-entrantes/formulario-entrantes/formulario-entrantes.component';
import { ContactDialogComponent } from './pages/adenu-internacional/contact-dialog/contact-dialog.component';
import { NotasTableComponent } from './pages/notas/components/notas-table/notas-table.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DocumentCardComponent } from './pages/perfil/components/document-card/document-card.component';
import { CarritoComponent } from './pages/matriculaciones/components/carrito/carrito.component';
import { ProductCardComponent } from './pages/matriculaciones/components/carrito/components/product-card/product-card.component';
import { ProductCounterComponent } from './pages/matriculaciones/components/carrito/components/product-counter/product-counter.component';
import { SimpleProductCardComponent } from './pages/matriculaciones/components/carrito/components/simple-product-card/simple-product-card.component';
import { CeadenComponent } from './pages/ceaden/ceaden.component';
import { ProgramCardComponent } from './pages/ceaden/components/program-card/program-card.component';
import { ProgramaImpulsoComponent } from './pages/ceaden/programa-impulso/programa-impulso.component';
import { FormImpulsoComponent } from './pages/ceaden/programa-impulso/components/form-impulso/form-impulso.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RegisterComponent } from './pages/register/register.component';
import { ModalidadDialogComponent } from './pages/matriculaciones/components/modalidad-dialog/modalidad-dialog.component';
import { HorariosComponent } from './pages/horarios/horarios.component';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import { TablaHorariosComponent } from './pages/horarios/components/tabla-horarios/tabla-horarios.component';
import { AnunciosComponent } from './pages/anuncios/anuncios.component';
import { CardAnuncioComponent } from './pages/anuncios/components/card-anuncio/card-anuncio.component';
import { DetalleAnuncioComponent } from './pages/anuncios/detalle-anuncio/detalle-anuncio.component';
import { CambiarAsignaturaDialogComponent } from './pages/matriculaciones/components/cambiar-asignatura-dialog/cambiar-asignatura-dialog.component';
import { SwiperModule } from 'swiper/angular';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ComiteEstudiantilComponent } from './pages/comite-estudiantil/sobre-adenpolis/comite-estudiantil.component';
import { PostulacionesAdenpolisComponent } from './pages/comite-estudiantil/postulaciones/postulaciones-adenpolis.component';
import { EleccionesAdenpolisComponent } from './pages/comite-estudiantil/elecciones/elecciones-adenpolis.component';
import { CardCandidatoComponent } from './pages/comite-estudiantil/components/card-candidato/card-candidato.component';
import { CardCandidatoSelectComponent } from './pages/comite-estudiantil/components/card-candidato-select/card-candidato-select.component';
import { HouseCardComponent } from './pages/comite-estudiantil/components/house-card/house-card.component';
import { WinnerCardComponent } from './pages/comite-estudiantil/components/winner-card/winner-card.component';
import { DracmaScoringComponent } from './pages/dracma-scoring/dracma-scoring.component';
import { DracmaTableComponent } from './pages/dracma-scoring/components/dracma-table/dracma-table.component';
import { MoreInfoComponent } from './pages/dracma-scoring/components/more-info/more-info.component';
import { DerechosPecuniariosComponent } from './pages/derechos-pecuniarios/derechos-pecuniarios.component';
import { ProductsCartComponent } from './pages/derechos-pecuniarios/components/products-cart/products-cart.component';
import { StudentCredentialComponent } from './pages/student-credential/student-credential.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CardTestimonioComponent } from './pages/ceaden/programa-impulso/components/card-testimonio/card-testimonio.component';
import { CartItemCardComponent } from './pages/derechos-pecuniarios/components/cart-item-card/cart-item-card.component';
import { OrderSucessDialogComponent } from './pages/derechos-pecuniarios/components/order-sucess-dialog/order-sucess-dialog.component';
import { OrdenesCompraComponent } from './pages/ordenes-compra/ordenes-compra.component';
import { OrdenesDerechosPecuniariosComponent } from './pages/ordenes-compra/ordenes-derechos-pecuniarios/ordenes-derechos-pecuniarios.component';
import { CardOrdenComponent } from './pages/ordenes-compra/components/card-orden/card-orden.component';
import { OrderDetailsComponent } from './pages/ordenes-compra/components/order-details/order-details.component';
import { TabsCarrerasComponent } from './pages/matriculaciones/components/tabs-carreras/tabs-carreras.component';
import { NotificacionesComponent } from './pages/notificaciones/notificaciones.component';
import { CursosAbiertosComponent } from './pages/cursos-abiertos/cursos-abiertos.component';
import { CardCursoAbiertoComponent } from './pages/cursos-abiertos/components/card-curso-abierto/card-curso-abierto.component';
import { GraduacionComponent } from './pages/graduacion/graduacion.component';
import { CourseCardComponent } from './pages/graduacion/components/course-card/course-card.component';
import { PracticaProfesionalComponent } from './pages/graduacion/practica-profesional/practica-profesional.component';
import { PracticaSocialComponent } from './pages/graduacion/practica-social/practica-social.component';
import { OpcionGradoComponent } from './pages/graduacion/opcion-grado/opcion-grado.component';
import { TestimonyCardComponent } from './pages/graduacion/components/testimony-card/testimony-card.component';
import { CourseSummaryDialogComponent } from './pages/graduacion/components/course-summary-dialog/course-summary-dialog.component';
import { OpcionGradoLinkComponent } from './pages/graduacion/components/opcion-grado-link/opcion-grado-link.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FileViewComponent } from './pages/file-view/file-view.component';
import { SpecViewComponent } from './pages/spec-view/spec-view.component';
import { LibraryComponent } from './pages/library/library.component';
import { VideoCardComponent } from './pages/library/components/video-card/video-card.component';
import { VideoPlayerComponent } from './pages/library/components/video-player/video-player.component';
import { CourseComponent } from './pages/course/course.component';
import { ResourceCardComponent } from './pages/course/resource-card/resource-card.component';
import { CourseViewerComponent } from './pages/course/course-viewer/course-viewer.component';
import { PaymentResumeComponent } from './pages/matriculaciones/components/payment-resume/payment-resume.component';
import { BuscojobsComponent } from './pages/buscojobs/buscojobs.component';
import { MultiDocsModalComponent } from './pages/perfil/components/multi-docs-modal/multi-docs-modal.component';
import { UploadQrComponent } from './pages/upload-qr/upload-qr.component';
import { SnowComponent } from './pages/home/snow.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AcademicAdvisorComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    ContestLayoutComponent,
    PerfilComponent,
    NotasComponent,
    PlanesEstudioComponent,
    TramitesComponent,
    CambioCarreraComponent,
    MultiDocsModalComponent,
    CartasComponent,
    CreditosComponent,
    DobleTitulacionComponent,
    RetiroCarreraComponent,
    NotFoundComponent,
    CardComponent,
    CardcanvasComponent,
    CardcareerComponent,
    CardadvisorComponent,
    FormComponent,
    CardprofileComponent,
    ModalComponent,
    MatriculacionesComponent,
    ModalConfirm,
    BannerComponent,
    CambioModalidadComponent,
    PaymentLinkDialogComponent,
    CreditosAcademicosComponent,
    EventosBannerComponent,
    ContractDialogComponent,
    TransferDialogComponent,
    ConcursoComponent,
    CronogramaConcursoComponent,
    FormConcursoComponent,
    HeadConcursoComponent,
    SobreConcursoComponent,
    CardCriterioComponent,
    CardRequisitoComponent,
    CardMiembroComponent,
    CardTutorComponent,
    PremiosConcursoComponent,
    CardCronogramaComponent,
    ContactoConcursoComponent,
    PeticionesComponent,
    SnowComponent,
    NuevoTicketComponent,
    DialogSuccess,
    EstadoTicketComponent,
    UploadConcursoComponent,
    DndDirective,
    DndInscripcionDirective,
    UploadInternacionalComponent,
    ContactoInternacionalComponent,
    CardCronogramaInternacionalComponent,
    PasosInternacionalComponent,
    SobreInternacionalComponent,
    HeadInternacionalComponent,
    FormInternacionalComponent,
    CronogramaInternacionalComponent,
    AdenuInternacionalComponent,
    CardPasoInternacionalComponent,
    CardRequisitoInternacionalComponent,
    FichaInscripcionComponent,
    ConveniosComponent,
    DetailDialogComponent,
    EstadoFinancieroComponent,
    EditPaymentComponent,
    PaymentCardComponent,
    PaymentTableComponent,
    EventosComponent,
    CardEventoComponent,
    GanadoresConcursoComponent,
    PlanTableComponent,
    MiProgresoComponent,
    CardAsignaturaComponent,
    AlertsMatriculaComponent,
    MovilidadEntrantesComponent,
    MovilidadDocentesComponent,
    FormularioEntrantesComponent,
    FormularioDocentesComponent,
    ContactDialogComponent,
    NotasTableComponent,
    CarritoComponent,
    ProductCardComponent,
    ProductCounterComponent,
    SimpleProductCardComponent,
    DocumentCardComponent,
    CeadenComponent,
    ProgramCardComponent,
    ProgramaImpulsoComponent,
    FormImpulsoComponent,
    RegisterComponent,
    ModalidadDialogComponent,
    HorariosComponent,
    TablaHorariosComponent,
    AnunciosComponent,
    CardAnuncioComponent,
    DetalleAnuncioComponent,
    CambiarAsignaturaDialogComponent,
    ComiteEstudiantilComponent,
    PostulacionesAdenpolisComponent,
    EleccionesAdenpolisComponent,
    CardCandidatoComponent,
    CardCandidatoSelectComponent,
    HouseCardComponent,
    WinnerCardComponent,
    DracmaScoringComponent,
    DracmaTableComponent,
    MoreInfoComponent,
    DerechosPecuniariosComponent,
    ItemPecuniarioCardComponent,
    ProductsCartComponent,
    StudentCredentialComponent,
    CardTestimonioComponent,
    CartItemCardComponent,
    OrderSucessDialogComponent,
    OrdenesCompraComponent,
    OrdenesDerechosPecuniariosComponent,
    CardOrdenComponent,
    OrderDetailsComponent,
    TabsCarrerasComponent,
    NotificacionesComponent,
    CursosAbiertosComponent,
    CardCursoAbiertoComponent,
    GraduacionComponent,
    CourseCardComponent,
    PracticaProfesionalComponent,
    PracticaSocialComponent,
    OpcionGradoComponent,
    TestimonyCardComponent,
    CourseSummaryDialogComponent,
    OpcionGradoLinkComponent,
    FileViewComponent,
    SpecViewComponent,
    LibraryComponent,
    VideoCardComponent,
    VideoPlayerComponent,
    CourseComponent,
    CourseCardComponent,
    ResourceCardComponent,
    CourseViewerComponent,
    PaymentResumeComponent,
    BuscojobsComponent,
    UploadQrComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    SharedModule,
    Ng2SearchPipeModule,
    StoreModule.forRoot(store),
    StoreDevtoolsModule.instrument({
      maxAge: 4,
    }),
    ClipboardModule,
    // NgxMatIntlTelInputComponent,
    CountdownModule,
    TourMatMenuModule.forRoot(),
    NgxHotjarModule.forRoot(environment.hotjar_id),
    NgxHotjarRouterModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    SwiperModule,
    RoundProgressModule,
    QRCodeModule,
  ],
  providers: [
    AuthGuard,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    Title
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
